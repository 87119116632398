
import CategoryList from './components/category_grid.jsx';

const App = () => {
  return (
    <>
        <CategoryList/>
    </>
  );
};

export default App;
